'use client';

import { useEffect } from 'react';
import { INLINE_MANUAL } from '../utils/variables';

export const InlineManualScript = (): JSX.Element => {
  useEffect(() => {
    if (!('inlinemanualPlayer' in window)) {
      const script = document.createElement('script');
      script.async = true;
      script.charset = 'UTF-8';
      script.src = `https://cdn.inlinemanual.com/embed/player.${INLINE_MANUAL}.bare.js`;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
    return () => {};
  }, []);

  return <></>;
};
